// src/components/js/Sponsors.js
import React, { useEffect } from 'react';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';
import '../css/Sponsors.css';

const sponsors = [
  { name: 'Sponsor 1', logo: '/img/partner-logo/logo-1.png' },
  { name: 'Sponsor 3', logo: '/img/partner-logo/logo-3.png' },
  { name: 'Sponsor 4', logo: '/img/partner-logo/logo-4.png' },
  { name: 'Sponsor 5', logo: '/img/partner-logo/logo-5.png' },
  { name: 'Sponsor 6', logo: '/img/partner-logo/logo-6.png' },
  { name: 'Sponsor 7', logo: '/img/partner-logo/logo-7.png' },
  { name: 'Sponsor 8', logo: '/img/partner-logo/logo-8.png' },
  { name: 'Sponsor 9', logo: '/img/partner-logo/logo-9.png' },
  { name: 'Sponsor 10', logo: '/img/partner-logo/logo-10.png' },
  { name: 'Sponsor 11', logo: '/img/partner-logo/logo-11.png' },
  { name: 'Sponsor 12', logo: '/img/partner-logo/logo-12.png' },
  { name: 'Sponsor 13', logo: '/img/partner-logo/logo-13.png' },
];

function Sponsors() {
  useEffect(() => {
    $('.sponsors-logos').slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4, // Mostra mais logos em telas grandes
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
    return () => {
      if ($('.sponsors-logos').hasClass('slick-initialized')) {
        $('.sponsors-logos').slick('unslick');
      }
    };
  }, []);

  return (
    <section className="sponsors">
      <div className="sponsors-logos">
        {sponsors.map((sponsor, index) => (
          <div key={index} className="sponsor">
            <img src={sponsor.logo} alt={sponsor.name} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Sponsors;
