import React from 'react';
import '../components/css/EventSponsors.css';
const EventSponsors = () => {
  return (
    <div className="event-sponsors">
      <header className="event-sponsors-header">
        <h1>Parcerias</h1>
      </header>

      {/*  <main className="event-sponsors-content">
    
        <section className="event-sponsor-category">
          <h2>Parcerias Institucionais</h2>
          <div className="event-sponsor-list">
            <div className="event-sponsor-item">
              <img src="img/institucional1.png" alt="Instituição 1" />
              <p>Instituição 1</p>
            </div>
            <div className="event-sponsor-item">
              <img src="img/institucional2.png" alt="Instituição 2" />
              <p>Instituição 2</p>
            </div>
          </div>
        </section>

    
        <section className="event-sponsor-category">
          <h2>Parcerias Científicas</h2>
          <div className="event-sponsor-list">
            <div className="event-sponsor-item">
              <img src="img/cientifica1.png" alt="Parceiro Científico 1" />
              <p>Parceiro Científico 1</p>
            </div>
            <div className="event-sponsor-item">
              <img src="img/cientifica2.png" alt="Parceiro Científico 2" />
              <p>Parceiro Científico 2</p>
            </div>
          </div>
        </section>

       
        <section className="event-sponsor-category">
          <h2>Parcerias Comerciais</h2>
          <div className="event-sponsor-list">
            <div className="event-sponsor-item">
              <img src="img/comercial1.png" alt="Parceiro Comercial 1" />
              <p>Parceiro Comercial 1</p>
            </div>
            <div className="event-sponsor-item">
              <img src="img/comercial2.png" alt="Parceiro Comercial 2" />
              <p>Parceiro Comercial 2</p>
            </div>
          </div>
        </section>

     
        <section className="event-sponsor-category">
          <h2>Outras Parcerias</h2>
          <div className="event-sponsor-list">
            <div className="event-sponsor-item">
              <img src="img/outras1.png" alt="Outra Parceria 1" />
              <p>Outra Parceria 1</p>
            </div>
            <div className="event-sponsor-item">
              <img src="img/outras2.png" alt="Outra Parceria 2" />
              <p>Outra Parceria 2</p>
            </div>
          </div>
        </section>
      </main> */}
    </div>
  );
};

export default EventSponsors;
