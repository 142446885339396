import React from 'react';
import '../App.css';

const members = [
  { name: 'Adélia Santos', workplace: 'ULSCoimbra' },
  { name: 'Adriana Baeta', workplace: 'ULSCoimbra' },
  { name: 'Alda Pinto', workplace: 'ULSCoimbra' },
  { name: 'Ana Carvalho', workplace: 'ULSCoimbra' },
  { name: 'Ana Cruz', workplace: 'ULSCoimbra' },
  { name: 'Ana Isabel Machado', workplace: 'ULSCoimbra' },
  { name: 'Ana Leitão', workplace: 'IPO Coimbra' },
  { name: 'Anabela Teixeira', workplace: 'ULSCoimbra' },
  { name: 'Bruno Esteves', workplace: 'ULSCoimbra' },
  { name: 'Carla Lourenço', workplace: 'ULSCoimbra-CSP' },
  { name: 'Carla Solano', workplace: 'ULSCoimbra' },
  { name: 'Carlos Nujo', workplace: 'ULSCoimbra-CSP' },
  { name: 'Carolina Gomes', workplace: 'ULSCoimbra' },
  { name: 'Daniel Matos', workplace: 'ULSCoimbra' },
  { name: 'Diana Carvalho', workplace: 'ULSCoimbra' },
  { name: 'Elisabete Freixo', workplace: 'ULSCoimbra' },
  { name: 'Elza Santos', workplace: 'ULSCoimbra' },
  { name: 'Filipa Martins', workplace: 'ULSCoimbra' },
  { name: 'Filipa Pires', workplace: 'ULSCoimbra' },
  { name: 'Gracinda Valente', workplace: 'ULSCoimbra' },
  { name: 'Inês Santos', workplace: 'IPO Coimbra' },
  { name: 'Inês Rodrigues', workplace: 'LPCC-Centro' },
  { name: 'Isabel Dias', workplace: 'ULSCoimbra-CSP' },
  { name: 'João Pedro', workplace: 'ULSCoimbra' },
  { name: 'Lucília Santos', workplace: 'ULSCoimbra' },
  { name: 'Magda Malva', workplace: 'ULSCoimbra' },
  { name: 'Marta Rosa', workplace: 'ULSCoimbra' },
  { name: 'Monica Martins', workplace: 'ULSCoimbra' },
  { name: 'Nuno Rodrigues', workplace: 'ULSCoimbra' },
  { name: 'Paula Marques', workplace: 'ULSCoimbra' },
  { name: 'Paulo Matos', workplace: 'ULSCoimbra' },
  { name: 'Patrícia Veludo', workplace: 'ULSCoimbra' },
  { name: 'Ricardo Leal', workplace: 'ULSCoimbra' },
  { name: 'Sara Silva', workplace: 'ULSCoimbra' },
  { name: 'Sónia Oliveira', workplace: 'ULSCoimbra' },
  { name: 'Teresa Fátima', workplace: 'ULSCoimbra' },
  { name: 'Tiago Araújo', workplace: 'ULSCoimbra' },
  { name: 'Tiago Patrão', workplace: 'ULSCoimbra' },
];

const ScientCommission = () => {
  return (
    <div className="commission-container">
      <h1 className="commission-title">Comissão Científica</h1>
      <div className="table-wrapper">
        <table className="commission-table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Local de Trabalho</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, index) => (
              <tr key={index}>
                <td>{member.name}</td>
                <td>{member.workplace}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScientCommission;
