import React from 'react';
import '../components/css/Hotels.css';

const hotels = [
  {
    name: 'Hotel Coimbra Aeminium',
    address: 'Alameda Armando Gonçalves, 20, 3000-059 Coimbra',
    contact: '239480800',
    details: (
      <span>
        <strong className="voucher">
          CIRC COIMBRA 2025 ou CIRC COIMBRA 2024
        </strong>
      </span>
    ),
    image: '/hotels/aeminium.jpg',
  },
  {
    name: 'Penedo da Saudade Suites e Hostel',
    address: 'Av. Dr. Marnoco e Sousa, 18B, 3000-271 Coimbra',
    contact: '937514507',
    details: (
      <span>
        10% de desconto com o código
        <strong className="voucher">CIRC Coimbra 2024</strong>
      </span>
    ),
    image: '/hotels/penedo.jpg',
  },
  {
    name: 'Seminário Maior',
    address: 'Rua Vandelli, 2, 3004-547 Coimbra',
    contact: '239792340',
    details:
      'Quarto individual - 60€; Quarto duplo - 80€; Pequeno almoço incluído',
    image: '/hotels/seminario.jpg',
  },
  {
    name: 'Hotel Íbis Coimbra Centro',
    address: 'Edifício Topázio, Av. Emídio Navarro, 70, 3000-150 Coimbra',
    contact: '239852130',
    details: (
      <span>
        10% de desconto com o código{' '}
        <strong className="voucher">CIRC2024</strong>
      </span>
    ),
    image: '/hotels/ibis.jpg',
  },
  {
    name: 'Hotel Astória – Coimbra',
    address: 'Av. Emídio Navarro, 21, 3000-150 Coimbra',
    contact: '+351 239 853 020',
    details: (
      <span>
        10% desconto sobre a nossa melhor tarifa online no site oficial do
        Hotel, REF: <strong className="voucher">CIRC 2025</strong>
      </span>
    ),
    image: '/hotels/astoria.jpg',
  },
  {
    name: 'Hotel Oslo Coimbra',
    address: 'Avenida Fernão de Magalhães, 25, 3000-175 Coimbra',
    contact: '+351 239 829 071',
    details: (
      <span>
        Desconto especial de 10% através do código promocional{' '}
        <strong className="voucher">LOVECOIMBRA</strong> no website oficial
      </span>
    ),
    image: '/hotels/oslo.jpg',
  },
  {
    name: 'Restaurante no Tacho',
    address: 'Rua da Morda, 20, 3000-282 Coimbra',
    contact: '911925961',
    details: (
      <span>
        10% em consumos iguais ou superiores a 30€ por pessoa com{' '}
        <strong className="voucher">TACHO10</strong>
      </span>
    ),
    image: '/hotels/tacho.jpg',
  },
  {
    name: 'Spagheti Notte',
    address: 'Rua Vitorino Nemésio, 397, 3030-360 Coimbra',
    contact: '919468371',
    details: (
      <span>
        <strong className="voucher">Welcome drink</strong>
      </span>
    ),
    image: '/hotels/notte.jpg',
  },
];

function Hotels() {
  return (
    <section className="hotels">
      <h2>Hotéis e Restaurantes Recomendados</h2>
      <div className="hotels-list">
        {hotels.map((hotel, index) => (
          <div key={index} className="hotel-card">
            <img src={hotel.image} alt={hotel.name} className="hotel-image" />
            <h3>{hotel.name}</h3>
            <p>{hotel.address}</p>
            <p className="phone">Contato: {hotel.contact}</p>
            <p>{hotel.details}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Hotels;
