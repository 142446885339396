import React, { useState } from 'react';
import '../components/css/Schedule.css';

const Schedule = () => {
  const [activeDay, setActiveDay] = useState('day1');

  const scheduleData = {
    day1: [
      {
        type: 'pdfAccess',
        theme: 'Curso 1 - SEGURANÇA EM RESSONÂNCIA MAGNÉTICA',
        pdfLink:
          'https://drive.google.com/file/d/1MgCtHXxKfprd7V2nqhnu19X6g5skF1a1/view?usp=share_link',
      },
      {
        type: 'pdfAccess',
        theme: 'Curso 2 - TC e RM CARDÍACAS',
        pdfLink:
          'https://drive.google.com/file/d/1Wzff4oFkpmXG62PXeGRdRdAj2eZWDjM5/view?usp=share_link',
      },
      {
        type: 'link',
        theme: 'Inscrição para Não Congressistas',
        pdfLink: 'https://form.jotform.com/250167096550053',
        text: 'Clique aqui para se inscrever como não congressista.',
      },
    ],
    day2and3: [
      {
        type: 'link',
        text: 'Clique aqui para visualizar o programa provisório completo.',
        pdfLink:
          'https://drive.google.com/file/d/1giD8NkzJOPU1xxU9MaAE1SsdpxG_VDZd/view',
      },
    ],
  };

  return (
    <section className="schedule-section">
      <h2>Programa Provisório</h2>
      <div className="tabs">
        <button
          className={activeDay === 'day1' ? 'tab active' : 'tab'}
          onClick={() => setActiveDay('day1')}
        >
          3 Abril
        </button>
        <button
          className={activeDay === 'day2and3' ? 'tab active' : 'tab'}
          onClick={() => setActiveDay('day2and3')}
        >
          4 e 5 Abril
        </button>
      </div>
      <div className="schedule-content">
        {activeDay === 'day1' &&
          scheduleData.day1.map((item, index) => (
            <div key={index} className="pdf-access-section">
              <h3>{item.theme}</h3>
              {item.type === 'link' ? (
                <a
                  href={item.pdfLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn"
                >
                  {item.text}
                </a>
              ) : (
                <a
                  href={item.pdfLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn"
                >
                  Aceder PDF
                </a>
              )}
            </div>
          ))}
        {activeDay === 'day2and3' && (
          <div className="pdf-link-section">
            <a
              href={scheduleData.day2and3[0].pdfLink}
              target="_blank"
              rel="noopener noreferrer"
              className="btn"
            >
              {scheduleData.day2and3[0].text}
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default Schedule;
