import React, { useState } from 'react';
import Popup from './Popup'; // Importa o componente Popup
import '../css/Prices.css';

function Prices() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsPopupOpen(true); // Abre o Popup ao selecionar
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const prices = [
    {
      title: 'Congressistas ULS Coimbra',
      currentPrice: '€40',
      futurePrice: '€80*',
      details: 'Inclui Coffee Break, Estacionamento e Almoço',
      highlight: 'Com Jantar: €75 / €115*',
    },
    {
      title: 'Congressistas Externos',
      currentPrice: '€80',
      futurePrice: '€160*',
      details: 'Inclui Coffee Break, Estacionamento e Almoço',
      highlight: 'Com Jantar: €115 / €195*',
    },
    {
      title: 'Estudantes IMR',
      currentPrice: '€60',
      futurePrice: '€120*',
      details: 'Inclui Coffee Break, Estacionamento e Almoço',
      highlight: 'Com Jantar: €95 / €155*',
    },
    {
      title: 'Congresso Virtual',
      currentPrice: '€60',
      details: 'Acesso ao conteúdo online',
    },
    {
      title: 'Pacote Extra de Jantar',
      currentPrice: '€35',
      details: 'Inclui jantar de gala',
    },
  ];

  return (
    <section className="prices">
      <h2>Inscrições</h2>
      <p className="description">
        Garanta o preço mais baixo até <strong>31 de janeiro de 2025</strong>.
        Após esta data, os preços serão ajustados*.
      </p>
      <div className="price-grid">
        {prices.map((item, index) => (
          <div
            key={index}
            className={`price-card ${
              selectedOption === item.title ? 'selected' : ''
            }`}
            onClick={() => handleSelect(item.title)}
          >
            <h3>{item.title}</h3>
            <div className="price-info">
              <p className="current-price">{item.currentPrice}</p>
              {item.futurePrice && (
                <p className="future-price">
                  <span>Após:</span> {item.futurePrice}
                </p>
              )}
            </div>
            <p className="details">{item.details}</p>
            {item.highlight && <p className="highlight">{item.highlight}</p>}
          </div>
        ))}
      </div>

      {/* Popup de Inscrição */}
      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          togglePopup={togglePopup}
          handleAcceptTerms={() => alert('Aceitou os Termos!')}
          handleViewRegulations={() => {
            togglePopup();
            alert('Redirecionando para o Regulamento');
          }}
        >
          <h3>Inscrição Selecionada</h3>
          <p>
            Você escolheu: <strong>{selectedOption}</strong>
          </p>
        </Popup>
      )}
    </section>
  );
}

export default Prices;
