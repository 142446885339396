import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import '../components/css/Exhibition.css';

const slideImages = [
  `${process.env.PUBLIC_URL}/exhibition/exhibition-3.jpg`,
  `${process.env.PUBLIC_URL}/exhibition/exhibition-13.jpg`,
  `${process.env.PUBLIC_URL}/exhibition/exhibition-16.jpg`,
];

function MedicalRadiologyExhibition() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on load
  }, []);

  return (
    <div className="exhibition-page">
      <h1 className="main-title">Medical Radiology Exhibition</h1>
      <div className="exhibition-content">
        <Swiper
          grabCursor={true}
          centeredSlides={true}
          slidesPerView="auto"
          loop={true}
          pagination={{ clickable: true }}
          navigation={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Navigation, Autoplay]}
          className="exhibition-swiper"
        >
          {slideImages.map((img, index) => (
            <SwiperSlide key={index}>
              <img
                src={img}
                alt={`Exhibit ${index + 1}`}
                className="exhibition-image"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="exhibition-text">
          <p className="justify-text">
            Desde a primeira hora que foi essencial que o Congresso
            Internacional de Radiologia de Coimbra-CIRC, imaging scientific
            talks, se cruzasse com a cidade e estrategicamente se assuma como um
            evento de interesse para Coimbra (CMC) e o turismo do conhecimento
            (ULSCoimbra).
          </p>
          <p className="justify-text">
            A AHD com o apoio do Convento de São Francisco e da Câmara Municipal
            de Coimbra, com o apoio científico da ULS Coimbra, e das diferentes
            associações profissionais, ATARP, NUCLIRAD, APIMR e o Alto
            Patrocínio de Senhor Presidente da Républica, Professor Doutor
            Marcelo Rebelo de Sousa, propõe, no tempo do congresso, outro evento
            que concretize a ideia de envolver a Radiologia com a cidade: a
            MEDICAL RADIOLOGY EXHIBITION.
          </p>
          <p className="justify-text">
            A MEDICAL RADIOLOGY EXHIBITION constitui-se como uma MOSTRA de
            produtos dirigidos, através de stands comerciais. Convidam-se os
            diferentes stakeholders da área da Radiologia a estarem presentes.
            Empresas de equipamentos, material de intervenção, de contrastes,
            decoração de interiores e hotelaria hospitalares, outros
            consumíveis, serviços de consultadoria, transformação digital,
            formação e todos os atores indispensáveis aos serviços de radiologia
            no século XXI.
          </p>
          <p className="justify-text">
            O CIRC e a MEDICAL RADIOLOGY EXHIBITION são indissociáveis. Este
            formato está pensado do modo seguinte:
          </p>
          <p className="justify-text">
            Stands, localizados numa zona preparada para os patrocinadores
            receberem os seus convidados, os convidados da organização, para
            além dos 400 congressistas. Prevê-se cerca de 550 pessoas por dia,
            no total, com acessos independentes.
          </p>
          <p className="justify-text">
            Os parceiros do CONGRESSO/MEDICAL EXHIBITION são livres de
            convidarem quem quiserem (nacionais e internacionais) para visitarem
            os seus stands durante os dois dias das 10.00h às 16.30h.
          </p>
          <p className="justify-text">
            Com este formato esperamos conseguir o nosso objetivo que é
            construir um congresso com valor científico relevante e, ao mesmo
            tempo, oferecer dois dias em que os atores, públicos e privados, do
            mundo da radiologia façam os seus negócios como em qualquer feira e
            os parceiros mostrem as suas novidades numa área que está em
            constante revolução.
          </p>
          <p className="justify-text">
            Queremos contribuir para a mudança de paradigma, e pautar a nossa
            relação com os parceiros como uma plataforma para os seus negócios e
            crescimento, criando relacionamentos sérios.
          </p>
          <p className="justify-text">
            No dia 5 de abril de 2025 queremos despedirmo-nos até 2027, com
            todos vós e os que virão.
          </p>
        </div>
      </div>
    </div>
  );
}

export default MedicalRadiologyExhibition;
