import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import Popup from './Popup';
import '../css/Navbar.css';

function Navbar() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setIsMenuOpen(false);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleAcceptTerms = () => {
    window.location.href = 'https://circ.eventqualia.net/pt/inicio/';
  };

  const handleViewRegulations = () => {
    window.location.href = '/event-regulation';
  };

  const handleLogoClick = () => {
    window.location.href = '/';
  };

  return (
    <header className="navbar">
      <div className="logo-container" onClick={handleLogoClick}>
        <img src="/logo.png" alt="CIRC Logo" className="logo-large" />
      </div>
      {/* Desktop Menu */}
      <nav className="desktop-menu">
        <div className="menu-links">
          <Link className="menu-item" to="/" onClick={closeMenu}>
            INÍCIO
          </Link>
          <Link className="menu-item" to="/exhibition/" onClick={closeMenu}>
            EXHIBITION
          </Link>
          <Link className="menu-item" to="/schedule" onClick={closeMenu}>
            PROGRAMA
          </Link>
          <Link className="menu-item" to="/sponsors" onClick={closeMenu}>
            PARCERIAS
          </Link>
          <Link className="menu-item" to="/hotels/" onClick={closeMenu}>
            HOTEL/REST
          </Link>
          <Link className="menu-item" to="/contacts" onClick={closeMenu}>
            CONTACTOS
          </Link>
        </div>
        <button
          className="menu-item inscription-highlight"
          onClick={togglePopup}
        >
          INSCRIÇÃO
        </button>
      </nav>
      {/* Mobile Menu */}
      <Menu
        right
        isOpen={isMenuOpen}
        onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)}
        styles={{
          bmMenuWrap: { top: '0', height: '100%' },
          bmOverlay: { background: 'rgba(0, 0, 0, 0.3)' },
        }}
      >
        <Link className="menu-item" to="/" onClick={closeMenu}>
          INÍCIO
        </Link>
        <Link className="menu-item" to="/exhibition/" onClick={closeMenu}>
          EXHIBITION
        </Link>
        <Link className="menu-item" to="/schedule" onClick={closeMenu}>
          PROGRAMA
        </Link>
        <Link className="menu-item" to="/sponsors" onClick={closeMenu}>
          PARCERIAS
        </Link>
        <Link className="menu-item" to="/hotels/" onClick={closeMenu}>
          HOTEL/REST
        </Link>
        <Link className="menu-item" to="/contacts" onClick={closeMenu}>
          CONTACTOS
        </Link>
        <button
          className="menu-item inscription-highlight"
          onClick={togglePopup}
        >
          INSCRIÇÃO
        </button>
      </Menu>
      <Popup
        isOpen={isPopupOpen}
        togglePopup={togglePopup}
        handleAcceptTerms={handleAcceptTerms}
        handleViewRegulations={handleViewRegulations}
      />
    </header>
  );
}

export default Navbar;
