import React, { useEffect } from 'react';
import '../App.css';

const Section = ({ title, children }) => (
  <section className="terms-of-use-section">
    <h3 className="section-title left-align">{title}</h3>
    <div className="section-content justify-text">{children}</div>
  </section>
);

const Ahd = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="terms-of-use">
      <h2 className="main-title">Associação Hemisfério Disciplinado-AHD</h2>

      <Section title="SOBRE NÓS">
        <p>
          Somos uma Associação sem fins lucrativos, Associação Hemisfério
          Disciplinado-AHD, de Coimbra, criada em 2022 por um grupo
          intergeracional da carreira dos técnicos superiores de diagnóstico e
          terapêutica de radiologia do Serviço de Imagem Médica da Unidade Local
          de Saúde de Coimbra- ULS Coimbra, antes CHUC E.P.E.
        </p>
      </Section>

      <Section title="MISSÃO, VISÃO E VALORES">
        <p>Segundo os nossos estatutos, a associação tem como fim:</p>
        <ul className="terms-of-use-list">
          <li>
            Contribuir para a inovação, aprofundamento e divulgação de
            conhecimento no âmbito da imagem médica;
          </li>
          <li>
            Promover a troca de informação e experiências entre profissionais de
            saúde, o cruzamento entre diferentes disciplinas;
          </li>
          <li>
            Organizar e apoiar a organização de congressos, seminários,
            colóquios, conferências, exposições, viagens de estudo, e outras
            atividades similares e afins;
          </li>
          <li>
            Promover a realização de ações de formação diversificada no âmbito
            do diagnóstico e terapêutica na área de Imagem Médica/Radiologia;
          </li>
          <li>
            Instituir práticas interativas fomentadoras de uma rede de saberes e
            experiências;
          </li>
          <li>Promover a atividade científica e de investigação;</li>
          <li>Promover a publicação científica;</li>
          <li>Promover a intergeracionalidade.</li>
        </ul>
      </Section>

      <Section title="OS NOSSOS OBJETIVOS ESPECÍFICOS">
        <ul className="terms-of-use-list">
          <li>
            Criar eventos com conceitos bem definidos e identidades visuais
            independentes;
          </li>
          <li>
            Incluir o Congresso Internacional de Radiologia de Coimbra (CIRC.
            imaging scientific talks) no circuito do conhecimento em Portugal e
            a nível europeu;
          </li>
          <li>
            Construir um evento monotemático e híbrido Radiologia ao Centro,
            Knowledge transforms lives que objetiva renovar competências, e seja
            agente da mudança;
          </li>
          <li>
            Valorizar os slogans (imaging scientific talks, Knowledge transforms
            lives) como parte nominal das marcas;
          </li>
          <li>
            Integrar acordos nacionais, internacionais e redes europeias de
            diferenciação;
          </li>
          <li>
            Destacar o potencial científico dos serviços públicos de Radiologia
            em Coimbra;
          </li>
          <li>
            Promover intercâmbios com outras instituições e cidades do
            conhecimento;
          </li>
          <li>Cruzar conhecimento com a economia local e o património.</li>
        </ul>
      </Section>

      <Section title="OS NOSSOS CONTATOS">
        <p>
          Rua das Romeiras, nº 38, Loja A<br />
          3030-471 Coimbra
          <br />
          NIF 517 072 262
          <br />
          E-mail: ahd-geral@outlook.com
        </p>
      </Section>
    </section>
  );
};

export default Ahd;
