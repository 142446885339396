import React, { useEffect } from 'react';
import '../App.css';

const contacts = {
  address: [
    'CIRC - Congresso Internacional de Radiologia de Coimbra',
    'Av. da Guarda Inglesa 1a',
    '3040-193 Coimbra - Portugal',
  ],
  registrations: {
    title: 'Receção de inscrições',
    name: 'Eventqualia',
    email: 'congress@eventqualia.net',
  },
  submissions: {
    title: 'Receção de submissões',
    name: 'Carla Solano',
    email: 'nucleodeformacaotr@gmail.com',
  },
  internationalRelations: {
    title: 'Relações internacionais',
    name: 'Paula Marques',
    email: 'circ.chuc@gmail.com',
  },
  exhibition: {
    title: 'MEDICAL RADIOLOGY EXHIBITION (Reserva de stands)',
    name: 'Carla Solano',
    email: 'circ.chuc@gmail.com',
  },
  accommodation: {
    title: 'Alojamento e Restauração',
    name: 'Marta Rosa',
    email: 'circ.chuc@gmail.com',
  },
  otherInfo: {
    title: 'Outras informações',
    email: 'circ.chuc@gmail.com',
  },
};

const ContactsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="contacts-page">
      <h2 className="main-title">Contactos</h2>
      <div className="contacts-container">
        <div className="contact-card">
          <h3>Endereço</h3>
          {contacts.address.map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
        {Object.values(contacts)
          .slice(1)
          .map((contact, index) => (
            <div className="contact-card" key={index}>
              <h3>{contact.title}</h3>
              {contact.name && (
                <p>
                  <strong>Nome:</strong> {contact.name}
                </p>
              )}
              <p>
                <strong>Email:</strong>{' '}
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </p>
            </div>
          ))}
      </div>
    </section>
  );
};

export default ContactsPage;
